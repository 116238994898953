import PropTypes from 'prop-types';

import noop from 'lodash/noop';
import omit from 'lodash/omit';

import formControl from '../form-control';
import inputPropTypes from '../utils/input-prop-types';
import formControlPropTypes from '../utils/form-control-prop-types';
import {inputValidError} from '../utils/helpers';
import CSSUtils from 'utils/css-utils';

import S from './input-field.less';

const {applyClassName} = CSSUtils;

const {any, func, oneOf} = PropTypes;

/**
 * @deprecated Use Formik and material-ui components instead.
 */
@formControl(inputValidError)
export default class InputField extends React.Component {
    static propTypes = {
        onEnter: func,
        style: any,
        type: oneOf(['text', 'password', 'hidden', 'number']),
        ...formControlPropTypes(PropTypes),
        ...inputPropTypes(PropTypes),
    };

    static defaultProps = {
        label: '',
        value: '',
        type: 'text',
        onEnter: noop,
    };

    constructor() {
        super();
    }

    _onChange(event) {
        const v = event.target.value;
        this.props.onChange(v);
    }

    _onKeyDown(e) {
        if (e.key === 'Enter') {
            this.props.onEnter(e);
        }
    }

    focus() {
        // eslint-disable-next-line react/no-string-refs -- SCLD-18005
        this.refs[this.props.id].focus();
    }

    render() {
        const {id, style, value, onTouched, type, touched, valid, disabled} = this.props;

        let cs = applyClassName('inputField', S, style);

        if (touched && !valid) {
            cs = applyClassName('invalid', S, style);
        }

        if (disabled) {
            cs = applyClassName('inputFieldDisabled', S, style);
        }

        const inputProps = omit(this.props, [
            'id',
            'value',
            'onChange',
            'style',
            'label',
            'messages',
            'touched',
            'onTouched',
            'className',
            'type',
            'valid',
            `triedSubmit`,
            `validator`,
            `pristine`,
            `isValidating`,
            `onEnter`,
        ]);

        return (
            <input
                className={cs}
                type={type}
                ref={id}
                value={value}
                {...inputProps}
                onChange={this._onChange.bind(this)}
                onBlur={onTouched}
                onKeyDown={this._onKeyDown.bind(this)}
            />
        );
    }
}
