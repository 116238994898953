import Immutable from 'immutable';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import {injectIntl, FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withLDConsumer} from 'launchdarkly-react-client-sdk';
import cloneDeep from 'lodash/cloneDeep';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';

import {
    authenticateSession,
    authenticateUser,
    getSettings,
    getSsoLoginUrl,
    registerSessionUser,
    saveNextLocation,
} from 'actions/session-actions';

import FormUtils from 'utils/form-utils';
import formManager from 'components/forms/form-manager';
import InputField from 'components/forms/components/input-field';
import LocalEnvironmentLogin from './components/local-environment-login';
import FeatureBranchLogin from './components/feature-branch-login';
import ProgressPulsingCircles from 'components/progress/progress-pulsing-circles';
import IntlUtils from 'utils/intl-utils';
import UIErrorCodeUtils from 'utils/ui-error-code-utils';

import globalMessages from 'intl/global-messages';
import messages from 'intl/anonymous-messages';
import ErrorCodes from 'constants/error-codes';
import SSOErrorTypes from 'constants/sso-error-types';

import S from './login.less';

const {any, func, bool, object} = PropTypes;
const allowFeatureBranchTestingOptions = __ALLOW_FEATURE_BRANCH_TESTING_OPTIONS__;
const loginShowClientInput = __LOGIN_SHOW_CLIENT_INPUT__;
const allowLocalTestingOptions = __ALLOW_LOCAL_TESTING_OPTIONS__;

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            authenticateSession,
            authenticateUser,
            getSettings,
            getSsoLoginUrl,
            registerSessionUser,
            saveNextLocation,
        },
        dispatch,
    );
}

class Login extends React.Component {
    static propTypes = {
        authenticateSession: func,
        authenticateUser: func.isRequired,
        client: any,
        getSettings: func,
        getSsoLoginUrl: func,
        handleSubmit: func.isRequired,
        intl: any,
        location: object,
        loginError: any,
        nextLocation: any,
        history: PropTypes.any,
        registerSessionUser: func,
        saveNextLocation: func,
        settings: any,
        valid: bool.isRequired,
        flags: object.isRequired,
    };

    static defaultProps = {
        getSsoLoginUrl: noop,
        registerSessionUser: noop,
        nextLocation: null,
        loginError: null,
        flags: {},
    };

    constructor(props, context) {
        super(props, context);

        const {location, settings} = props;
        const searchParams = new URLSearchParams(location.search);
        const ssoErrorType = searchParams.get('error');
        const ssoErrorTraceId = searchParams.get('errorTraceId');

        this.state = {
            inProgress: false,
            loggedIn: false,
            focusedElement: null,
            loginMinimized: settings.get('ssoEnabled'),
        };

        // nextLocation is set by signed-in-routes.js when you try to view a signed-in route without being logged in.
        // We need to redirect you to that location after logging in
        this.nextLocation =
            isNil(location) ||
            isNil(location.state) ||
            isNil(location.state.nextLocation) ||
            location.state.nextLocation.pathname.includes('reset-password')
                ? null
                : location.state.nextLocation;

        if (!isNil(ssoErrorType)) {
            this.state.ssoErrorType = ssoErrorType;
            this.state.ssoErrorTraceId = ssoErrorTraceId;
        }
    }

    async componentDidMount() {
        if (!isNil(this.state.ssoErrorType)) {
            // clear any errors from location once we've had a chance to deal with them
            const nextLocation = cloneDeep(this.props.location);
            const searchParams = new URLSearchParams(nextLocation.search);
            searchParams.delete('error');
            searchParams.delete('errorTraceId');
            nextLocation.search = searchParams.toString();
            this.props.history.replace(nextLocation);
        }

        const {location, flags, settings} = this.props;
        const searchParams = new URLSearchParams(location.search);
        const useSimCaptureLogin = searchParams.get('useSimCaptureLogin') === 'true';

        // Only try to do this when the flags load in after first render
        const isLaerdalConnectEnabled =
            flags.useLaerdalConnectAuthentication && !isNil(settings.get('laerdalOrganizationId'));
        if (isLaerdalConnectEnabled && !useSimCaptureLogin) {
            this.props.history.replace('/login/laerdal');
        }
    }

    focus(element) {
        // eslint-disable-next-line react/no-find-dom-node -- SCLD-16839
        ReactDOM.findDOMNode(element).focus();
    }

    inputFocus(ref) {
        this.setState({
            focusedElement: ref,
        });
    }

    login(data) {
        const username = data.get('username');
        data = data.set('username', username.trim());
        if (isNil(data.get('clientSubdomain'))) {
            const urlParts = window.location.hostname.split('.');
            if (urlParts.length !== 3) {
                throw new Error(
                    'Invalid system configuration. client should be passed explicitly, or inferrable from URL',
                );
            }
            data = data.set('clientSubdomain', urlParts[0]);
        }

        this.setState({
            focusedElement: null,
            inProgress: true,
        });

        const onLoginSuccess = this.onLoginSuccess.bind(this);
        const onLoginFailure = this.onLoginFailure.bind(this);
        this.props.authenticateUser(data, onLoginSuccess, onLoginFailure);
    }

    onLoginSuccess() {
        // eslint-disable-next-line no-shadow -- SCLD-17998
        const {location, history, getSettings, flags} = this.props;

        // save next location
        const goToNextPage = () => {
            const urlParams = new URLSearchParams(location.search);

            // If nextLocation is set, this is redirecting to another route within SCLD
            if (!isNil(this.nextLocation)) {
                history.replace(this.nextLocation);
            }
            // There is no redirect from a SCLD route, but a query param for a redirect is being used
            else if (urlParams.has('redirect')) {
                const redirect = urlParams.get('redirect');

                // Locally, a valid redirect will only be the full URL with the scheme and origin for http://localhost.
                // In all other environments, only path parameters will be considered valid redirects.
                // This will be appended to the current simcapture host origin.
                if (__UI_SERVICE__ === 'local') {
                    const redirectIsValid = redirect.startsWith('http://localhost:');
                    if (redirectIsValid) {
                        window.location.assign(redirect);
                    } else {
                        history.replace('/');
                    }
                } else {
                    const redirectIsValid = redirect.startsWith('/');
                    if (redirectIsValid) {
                        window.location.assign(`${window.location.origin}${redirect}`);
                    } else {
                        history.replace('/');
                    }
                }
            }
            // There is no valid redirect required, go to the dashboard
            else {
                history.replace('/');
            }
        };

        const successFn = (settings) => {
            const {loggedIn} = this.state;

            // don't change route twice if multiple getSettings requests succeed
            if (!loggedIn) {
                this.setState({
                    loggedIn: true,
                });
                IntlUtils.updateTimeFormats(settings);
                IntlUtils.updateLocaleReloadAll(goToNextPage, flags.useSmartling);
            }
        };

        const failFn = () => {
            goToNextPage();
        };

        getSettings(successFn, failFn);
    }

    onLoginFailure(meteorError) {
        this.setState({
            inProgress: false,
        });

        if (meteorError) {
            const errorCode = meteorError.get('errorCode');

            if (UIErrorCodeUtils.errorCodesAreEqual(errorCode, ErrorCodes.LOGIN_ERROR)) {
                // select password field
                // eslint-disable-next-line react/no-string-refs -- SCLD-18005
                this.refs.password.focus();
                // clear password field
                // eslint-disable-next-line react/no-string-refs -- SCLD-18005
                this.refs.password.reset();
            } else if (UIErrorCodeUtils.errorCodesAreEqual(errorCode, ErrorCodes.LOGIN_CLIENT_NOT_FOUND)) {
                // client field is sometimes hidden so check for it
                // eslint-disable-next-line react/no-string-refs -- SCLD-18005
                if (this.refs.client) {
                    // eslint-disable-next-line react/no-string-refs -- SCLD-18005
                    this.refs.client.focus();
                    // eslint-disable-next-line react/no-string-refs -- SCLD-18005
                    this.refs.client.reset();
                }
            }
        }
    }

    requestResetPassword = () => {
        this.props.history.push('/request-reset-password');
    };

    // Redirects the user to the appropriate SSO IDP login url for their client.
    onSsoLogin = () => {
        this.props.getSsoLoginUrl((ssoLoginUrl) => {
            window.location.href = ssoLoginUrl;
        });
    };

    getLoginErrorText(loginErrorCode) {
        let message = messages.unknownError;
        const _loginErrorCode = Immutable.isImmutable(loginErrorCode) ? loginErrorCode.toJS() : loginErrorCode;
        switch (_loginErrorCode.errorCode) {
            case ErrorCodes.TOKEN_INVALID.errorCode:
                message = messages.tokenInvalid;
                break;
            case ErrorCodes.LOGIN_CLIENT_NOT_FOUND.errorCode:
                message = messages.clientNotFound;
                break;
            case ErrorCodes.LOGIN_LOCKOUT.errorCode:
                message = messages.loginLockout;
                break;
            case ErrorCodes.LOGIN_ERROR.errorCode:
                message = messages.loginError;
                break;
            case ErrorCodes.LOGIN_INACTIVE.errorCode:
                message = messages.loginInactive;
                break;
        }
        return (
            <p className={S.errorMessage}>
                <FormattedMessage {...message} />
            </p>
        );
    }

    getSsoErrorText(ssoErrorType, ssoErrorTraceId) {
        let message = messages.loginErrorSsoUnknownError;

        switch (ssoErrorType) {
            case SSOErrorTypes.MISSING_RELAY_STATE:
                // we should use this message if the user can't feasibly recover from the error
                message = messages.loginErrorSsoErrorOccurred;
                break;
            case SSOErrorTypes.INVALID_RELAY_STATE:
                message = messages.loginErrorSsoInvalidRelayState;
                break;
            case SSOErrorTypes.INVALID_SSO_EXTERNAL_ID:
                message = messages.loginErrorSsoInvalidSsoExternalId;
                break;
            case SSOErrorTypes.SSO_NOT_ENABLED:
                message = messages.loginErrorSsoNotEnabled;
                break;
            case SSOErrorTypes.USER_INACTIVE:
                message = messages.loginErrorSsoUserInactive;
                break;
            case SSOErrorTypes.USER_NOT_FOUND:
                message = messages.loginErrorSsoUserNotFound;
                break;
            case SSOErrorTypes.UNKNOWN_ERROR:
                message = messages.loginErrorSsoUnknownError;
                break;
        }

        return (
            <div>
                <p className={S.errorMessage}>
                    <FormattedMessage {...message} />
                </p>
                <p className={S.errorMessage}>
                    <FormattedMessage {...messages.loginErrorTraceId} values={{traceId: ssoErrorTraceId}} />
                </p>
            </div>
        );
    }

    onLoginMinimizeClick = () => {
        this.setState(
            {
                loginMinimized: false,
            },
            () => {
                if (loginShowClientInput) {
                    // eslint-disable-next-line react/no-string-refs -- SCLD-18005
                    this.refs.client.focus();
                } else {
                    // eslint-disable-next-line react/no-string-refs -- SCLD-18005
                    this.refs.username.focus();
                }
            },
        );
    };

    render() {
        const {intl, loginError: loginErrorCode, settings, valid} = this.props;
        const {inProgress, loginMinimized, ssoErrorTraceId, ssoErrorType} = this.state;
        const {formatMessage} = intl;

        const onSubmit = (...args) => {
            // Block submissions if this is minimized
            if (loginMinimized) {
                return;
            } else {
                this.props.handleSubmit(this.login.bind(this))(...args);
            }
        };

        let tabIndex = 0;

        let submitButton;
        if (loginMinimized) {
            // Make the button a pretty blue if the login prompt is minimized
            submitButton = S.submitButton;
        } else if (valid) {
            submitButton = S.submitButton;
        } else {
            submitButton = S.submitButtonDisabled;
        }

        const onKeyDown = (event) => {
            if (event.key === 'Enter') {
                onSubmit(event);
            }
        };

        let errorText = !isNil(loginErrorCode) ? this.getLoginErrorText(loginErrorCode) : null;
        if (isNil(errorText) && !isNil(ssoErrorType)) {
            errorText = this.getSsoErrorText(ssoErrorType, ssoErrorTraceId);
        }

        let usernameOrEmailLabel = messages.username;
        let usernameOrEmailPlaceHolder = messages.usernamePlaceholder;
        if (!settings.get('uniqueDomain', false)) {
            usernameOrEmailLabel = messages.email;
            usernameOrEmailPlaceHolder = messages.emailPlaceholder;
        }

        return (
            <div className={S.main}>
                <form className={S.form} noValidate onSubmit={FormUtils.preventSubmit}>
                    {settings.get('ssoEnabled') && (
                        <div>
                            <div
                                className={inProgress ? S.ssoButtonDisabled : S.ssoButton}
                                tabIndex={valid ? ++tabIndex : -1}
                                onClick={this.onSsoLogin}>
                                <i className={S.ssoIcon}>vpn_key</i>
                                <FormattedMessage {...messages.ssoLogin} />
                            </div>
                            <div className={S.ssoDivider}>
                                <FormattedMessage {...globalMessages.or} />
                            </div>
                        </div>
                    )}
                    <div
                        className={S.inputsContainer}
                        style={{
                            maxHeight: loginMinimized ? '0' : '100vh',
                        }}>
                        {loginShowClientInput && (
                            <div>
                                <label htmlFor='client_name' className={S.labelFirst}>
                                    <FormattedMessage {...messages.client} />
                                </label>
                                <InputField
                                    autoFocus={true}
                                    // eslint-disable-next-line react/no-string-refs -- SCLD-18005
                                    ref='client'
                                    name='clientSubdomain'
                                    className={S.input}
                                    id='client_name'
                                    autoCapitalize='off'
                                    required={true}
                                    tabIndex={++tabIndex}
                                    onEnter={onSubmit}
                                    onFocus={this.inputFocus.bind(this, 'client')}
                                    disabled={loginMinimized}
                                />
                            </div>
                        )}
                        <label htmlFor='username_login' className={loginShowClientInput ? S.label : S.labelFirst}>
                            <FormattedMessage {...usernameOrEmailLabel} />
                        </label>
                        <InputField
                            // eslint-disable-next-line react/no-string-refs -- SCLD-18005
                            ref='username'
                            name='username'
                            className={S.input}
                            autoCapitalize='off'
                            id='username_login'
                            required={true}
                            onFocus={this.inputFocus.bind(this, 'username')}
                            placeholder={formatMessage(usernameOrEmailPlaceHolder)}
                            tabIndex={++tabIndex}
                            onEnter={onSubmit}
                            disabled={loginMinimized}
                        />
                        <label htmlFor='password_login' className={S.label}>
                            <FormattedMessage {...messages.password} />
                        </label>
                        <InputField
                            // eslint-disable-next-line react/no-string-refs -- SCLD-18005
                            ref='password'
                            name='password'
                            className={S.input}
                            autoCapitalize='off'
                            placeholder={formatMessage(messages.passwordPlaceholder)}
                            tabIndex={++tabIndex}
                            required={true}
                            type='password'
                            onFocus={this.inputFocus.bind(this, 'password')}
                            onEnter={onSubmit}
                            disabled={loginMinimized}
                        />
                        <div className={S.forgotPasswordText} onClick={this.requestResetPassword}>
                            <FormattedMessage {...messages.forgotPassword} />
                        </div>
                    </div>
                    {inProgress ? (
                        <div className={S.loadingProgress}>
                            <ProgressPulsingCircles />
                        </div>
                    ) : (
                        <div
                            className={submitButton}
                            onKeyDown={onKeyDown}
                            // eslint-disable-next-line react/no-string-refs -- SCLD-18005
                            ref='submitButton'
                            id='submit_button'
                            tabIndex={valid ? ++tabIndex : -1}
                            onClick={loginMinimized ? this.onLoginMinimizeClick : onSubmit}>
                            <FormattedMessage {...messages.login} />
                        </div>
                    )}
                    <div className={S.errorText}>{errorText}</div>
                </form>
                {allowLocalTestingOptions && <LocalEnvironmentLogin onLogin={this.login.bind(this)} />}
                {allowFeatureBranchTestingOptions && <FeatureBranchLogin onLogin={this.login.bind(this)} />}
            </div>
        );
    }
}

export default withLDConsumer()(
    injectIntl(
        formManager()(
            connect(
                (state) => ({
                    loginError: state.session.loginError,
                    client: state.session.client,
                    nextLocation: state.session.nextLocation,
                    settings: state.session.settings,
                }),
                mapDispatchToProps,
                null,
                {forwardRef: true},
            )(Login),
        ),
    ),
);
